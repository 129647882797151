<div
  class="d-flex"
  [ngClass]="{
    'justify-content-start': position === 'left',
    'justify-content-end': position === 'right',
  }"
>
  <div class="chat-message-container">
    <div class="d-flex justify-content-between align-items-end gap-2 mb-2">
      <div class="font-14">{{ message?.created_at | date: "HH:mm dd-MM" }}</div>

      <div class="d-flex align-items-end gap-2">
        <div class="font-14">{{ message?.name }}</div>

        @if (message.sender === "user") {
          <shared-avatar
            class="cursor-pointer"
            size="extra-small"
            [avatarUrl]="message.avatar"
            [userName]="message.name"
            (click)="onUserAvatarClick()"
          ></shared-avatar>
        } @else {
          <img
            class="chat-message-ai-avatar"
            [src]="message.avatar"
            [alt]="message.name"
          />
        }
      </div>
    </div>

    <div
      #messageContainer
      class="os-block-blue-outline p-3"
      [innerHTML]="message?.content | safeHtml"
    ></div>
  </div>
</div>

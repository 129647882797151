export type MessageSender = "ai" | "user";

export class Message {
  public id?: number;
  public sender?: MessageSender;
  public content?: string;
  public created_at?: string | Date;
  public conversation?: number;

  public name?: string;
  public avatar?: string;

  constructor(message: Message) {
    Object.assign(this, message);
  }

  public static Factory(message: Message) {
    return new Message(message);
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { AvatarComponent } from "@app/shared/basic-components";
import { Message } from "@app/shared/models/entities/ai-teacher/Message";
import { SafeHtmlPipe } from "@app/shared/pipes";
import { KatexService } from "@app/shared/services/utility-services/katex.service";

export type MessageAlign = "left" | "right";

@Component({
  selector: "chat-message",
  standalone: true,
  templateUrl: "./chat-message.component.html",
  styleUrl: "./chat-message.component.scss",
  imports: [CommonModule, AvatarComponent, SafeHtmlPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent implements OnInit, AfterViewInit {
  @Input() protected message!: Message;
  @Input() protected position: MessageAlign = "left";

  @ViewChild("messageContainer") private readonly messageContainer!: ElementRef<HTMLDivElement>;

  constructor(
    private readonly router: Router,
    private readonly katexService: KatexService
  ) {}

  ngOnInit() {
    if (this.message.sender === "ai") {
      this.message.content = this.katexService.parseMathExpressions(this.message.content || "");
    }
  }

  ngAfterViewInit() {
    if (this.message.sender === "ai") {
      this.katexService.renderMath(this.messageContainer.nativeElement);
    }
  }

  protected onUserAvatarClick() {
    this.router.navigate(["/gebruiker/account/avatar"]);
  }
}

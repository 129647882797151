<div
  #container
  class="position-relative d-flex flex-column gap-3 flex-grow-1 overflow-y-auto"
>
  @for (message of messages; track message) {
    @if (message.content) {
      <chat-message
        [position]="message.sender === 'user' ? 'right' : 'left'"
        [message]="message"
      ></chat-message>
    } @else {
      <div class="d-flex">
        <loading-dots-blue></loading-dots-blue>
      </div>
    }
  }

  @if (messages.length) {
    <div>
      <div class="font-14 os-bold mb-2">Wil je een medewerker spreken?</div>

      <div class="d-flex flex-wrap gap-3">
        <!-- TODO: Temporarily hidden until the AI Teacher can handle a helpful response -->
        <!-- <shared-button-->
        <!--   text="Ja, ik ben geholpen!"-->
        <!--   [style]="'secondary'"-->
        <!--   (clicked)="onHelpfulResponseClick()"-->
        <!-- ></shared-button>-->

        <shared-button
          text="Medewerker spreken"
          [style]="'secondary'"
          (clicked)="onContactEmployeeClick()"
        ></shared-button>
      </div>
    </div>
  }
</div>
